import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import FAQ from '../components/faq/faq';

import faqs from '../components/faq/bmw-faqs';
import ImageBackground from "../components/imagebackground";
import settings from "../../settings";

const banner =`${settings.IMAGES_BASE_URL}/images/home/faq-banner.jpg`;

const SchoolFAQPage = () => {
  return (
    <Layout>
      <SEO title="BMW Performance Driving School | FAQ" description="Frequently asked questions about BMW Performance Driving School - from the right gear to what you can expect here, get the answers you need." />
      <div className="faq-page schoolfaq">
        <ImageBackground
          imageDesktop={banner}
          imageMobile={banner}
          imageAlt="press"
          imageClassName="banner"
          topLeftChildren={
          <div>
            <div className="theme__text">
              <div>
                <h3>SCHOOL FREQUENTLY</h3>
                <h3>ASKED QUESTIONS</h3>
              </div>
            </div>
            <div className="subheader">Listed below are some common questions regarding<br/> driving programs offered by BMW. For additional help,<br/> please call us at <a className="phone" href="tel:888-345-4269">888-345-4269.</a></div>
          </div>
          }
          hideArrow
        />
        <section className="faq">
          <div className="container">
            <FAQ faqs={faqs}/>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default SchoolFAQPage;
